import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Layout from '../components/layout.component'
import SEO from '../components/seo.component'
import BackgroundImage from 'gatsby-background-image'

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "header-bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 250) {
            src
          }
        }
      }
    }
  `)
  const bgImageData = data.image.childImageSharp.fluid

  return (
    <Layout>
      <BackgroundImage
        Tag="header"
        className="Header Header--small"
        fluid={bgImageData}
        backgroundColor={`#2b2b2b`}
      >
        <SEO title="404: Not found" />
        <header className="Header">
          <div className="Header__line"></div>
          <div className="Header__text">
            <h1 className="Heading--primary">
              Sorry page was not found <br />
            </h1>
          </div>
        </header>
      </BackgroundImage>

      <section>
        <p>Uh-oh! You just hit a page that doesn&#39;t exist.</p>
        <Link to="/" className="Btn Btn--dark">
          Go back home
        </Link>
      </section>
    </Layout>
  )
}

export default NotFoundPage
